import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Typography, Row, Col, Divider, List } from "antd"

import Layout from "../components/layout"
import SEO from "../components/seo"

const { Title, Paragraph } = Typography

const AboutPage = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <Title>{post.frontmatter.title}</Title>
          <Paragraph>{post.frontmatter.date}</Paragraph>
        </header>

        <Row gutter={[48, 8]} justify="space-around">
          <Col md={4}>
            <Image fluid={post.frontmatter.banner.childImageSharp.fluid} />
          </Col>
          <Col md={12}>
            <MDXRenderer post={post}>{post.body}</MDXRenderer>
          </Col>
          <Col md={8}>
            <Divider orientation="left">Links</Divider>
            <List
              size="large"
              bordered
              dataSource={post.frontmatter.links}
              renderItem={item => (
                <List.Item>
                  <a href={item.link} rel="noreferrer" target="_blank">
                    {item.name}
                  </a>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </article>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fileAbsolutePath: { regex: "/content/about/" }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        links {
          name
          link
        }
        banner {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
